<script>
import { cloudImageBaseUrl } from '@/CONF.js'
import { appDown, iosDownloadPath } from  "@/api/home";
export default {    
    components:{
        Header: () => import('components/base/Header'),
    },
    data(){
        return{
            android:false,
            type:"",//1 IOS 2安卓
            result:'',
            imgUrlTitle:'//cpfiles.'+process.env.VUE_APP_PROD_DOMAIN+'/appDown/',//oss图片地址头
            qrSize:(parseFloat(document.documentElement.style.fontSize)/50)*140,

            header:{
                mainTitle: '',
            },
            logoUrl:null,
            maintitle:null,
            phase:1,
            iosDownload:null,
            iosDownloadPath:null,
            mobileconfig:null,
        }
    },
    methods:{
        ieClick(){
            this.$router.replace({path:'/home'});
        },
        appDowm(){
            window.location.href=this.result;
        },

        downloadApp(type){
            var type=type==='android'?'2':'1';
            if (type==='1') {
                this.iosDownload=true;
                return false;
            }
            appDown(type).then(result => {
                if(result.data.code=='SUCCESS'){
                    if(type === '1'){
                        this.result='itms-services://?action=download-manifest&url='+result.data.result.url; 
                    }else if(type === '2'){
                        window.location.href=result.data.result.url; 
                    }
                }else{
                    app.Mint.Toast(result.data.msg);
                }
            })
        },
        getIosDownloadPath(mode){

            iosDownloadPath().then(result=>{
                if(result.data.code=='SUCCESS'){
                    if(mode===3) {
                        if (result.data.result[0].type3) {
                            window.open(cloudImageBaseUrl+'/'+result.data.result[0].type3.qrcode);
                            setTimeout(()=>{
                                 window.location.href=window.location.origin+'/static/assets/app/embedded1.mobileprovision';
                            },2000)
                        }else {
                            app.Mint.Toast(getWord('no_light_package_yet'));
                            return false;
                        }
                    }
                    if(window.location.href=result.data.result[0].type2){
                        window.location.href=result.data.result[0].type2.url
                    }else {
                        window.location.href='itms-services://?action=download-manifest&url='+result.data.result[0].type1.url
                    }
                }else {
                    app.Mint.Toast(result.data.msg);
                }
            })
        },
        changePhase(index) {
            this.$router.push({path:'/downloadApp/IosQrCode'});
        },
        showManual(){
            this.$router.push({path:'/downloadApp/IosManual'});
        }
    },
    mounted(){
        this.CustomScroll(this.$refs.download.id);

        if (window.localStorage.getItem('logourl')) {
            this.logoUrl=window.localStorage.getItem('logourl');
        }        
        if (window.localStorage.getItem('maintitle')) {
            this.maintitle=window.localStorage.getItem('maintitle');
        }
    }
};
</script>

<template>
    <section id="download" ref="download">
        <Header
            :mainTitle="header.mainTitle"
            :backButton=false
            :transparent=true
        /> 
        <template v-if="phase===1">
            <div class="home"> 
                <img class="logo" :src="logoUrl" />
                <h2>{{maintitle}}</h2>
            </div>
            <div class="buttons">
                <a class="download" @click="downloadApp('android')">{{ getWord('android_download') }}</a>
                <a class="download" @click="downloadApp('ios')">{{ getWord('ios_download') }}</a>
                <i class="iconfont icon-down1" @click="showManual()"></i>
            </div>
            <aside class="download" v-if="iosDownload">
                <div class="shadow" @click='iosDownload=false'></div>
                <div class="inner">
                    <h3>{{ getWord('select_download_adress') }}<span>{{ getWord('app_download_rules') }}</span></h3>

                    <div class="block">
                        <div class="left">
                            <h4>{{ getWord('app_download') }}<span class="level3">{{ getWord('hot') }}</span></h4>
                            <p>{{ getWord('app_download_rules2') }}</p>
                        </div>
                        <a @click="getIosDownloadPath()">{{ getWord('download2') }}</a>
                    </div>

                    <div class="block">
                        <div class="left">
                            <h4>{{ getWord('light_package') }}<span class="level2">{{ getWord('recommend') }}</span></h4>
                            <p>{{ getWord('app_download_rules2') }} <a @click='showManual()'>{{ getWord('installation_description') }}</a></p>
                        </div>
                        <!-- <a :href="require('@@/assets/app/ios.mobileconfig')">点击下载</a> -->
                        <a @click="getIosDownloadPath(3)">{{ getWord('download2') }}</a>
                    </div>
                </div>
            </aside>
        </template>
    </section>
</template>

<style scoped lang='scss' type="text/css">
#download {
    overflow: auto;
    background-image: url('/static/assets/images/home/app/home.png');
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    flex-direction: column;

    img {
        max-width: 100%;
    }

    .home {
        height: 5.625rem;
        position: relative;

        .logo {
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                top: 0;
                margin: auto;
                max-width: 4rem;
                max-height: 2rem;
                transform: translateY(-.5rem);
        }

        h2 {
            color: #ffffff;
            font-weight: normal;
            position: absolute;
            text-align: center;
            top: 50%;
            width: 100%;
            margin-top: .5rem;
        }
    }

    .buttons {
        flex:1 1 1rem;
        text-align: center;
        padding: 1rem 0;
        position: relative;

        a {
            text-align: center;
            color: #ffffff;
            font-size: .3rem;
            background-color: #417CFF;
            padding: .25rem 0.6rem;
            display: block;
            width: 2rem;
            margin: .5rem auto;
        }

        i {
            font-size: .64rem;
            color: #417CFF;
            position: absolute;
            bottom: 1rem;
        }
    }

    aside {

        &.download {
            position: fixed;
            top:0;
            bottom:0;
            left:0;
            right:0;

            .shadow {                
                background-color: rgba(0,0,0,.3);
                position: absolute;
                top:0;
                bottom:0;
                left:0;
                right:0;
                z-index: 1;
            }

            .inner {
                background-color: #ffffff;
                position: absolute;
                z-index: 2;
                bottom: 0;
                left:0;
                right: 0;
                padding-bottom: 1.5rem;

                h3 {
                    text-align: center;
                    font-size: .36rem;
                    border-bottom: 1px solid #d0d0d0;
                    font-weight: normal;
                    padding: .25rem 0;

                    span {
                        margin-top: .1rem;
                        font-size: .22rem;
                        color: #d0d0d0;
                        display: block;
                    }
                }

                .block {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: .25rem;

                    .left {
                        flex: 0 0 3.6rem;
                    }

                    h4 {
                        font-size: .28rem;
                        font-weight: normal;

                        span {
                            font-size: .18rem;
                            color: #ffffff;
                            padding: 0 .125rem;
                            margin-left: .1rem;

                            &.level2 {
                                background-color: #FE7B60;
                            }

                            &.level3 {
                                background-color: #EC2829;
                            }
                        }
                    }

                    p {
                        color: #999999;
                        font-size: .22rem;

                        > a {
                            color: #417CFF;
                        }
                    }

                    > a {
                        font-size: .3rem;
                        color: #417CFF;
                        border:1px solid #417CFF;
                        padding: .1rem .25rem;
                        height: 0.4rem;
                    }
                }
            }
        }

    }
}
.myposition{
    position: fixed;
    top:0;
    left:0;
    right:0; 
    bottom: 0;
    z-index:888;
    background:#000;
    opacity:0.5;
}

.QR_code{
    float: left;
    width:2.7rem;
    height:3.41rem;
    margin:0rem 0 0 0.8rem;
}
.QR_css{
    float: left;
    float:left;
    width:2.7rem;
    height:2.7rem;
}
.QR_text{
    float:left;
    width:2.7rem;
    height:0.71rem;
    background:rgba(96,164,254,1);
    font-size:0.36rem;
    font-family:MicrosoftYaHei;
    font-weight:400;
    color:rgba(255,255,255,1);
    text-align: center;
    line-height: 0.71rem;
}
.dowm_button{
    float: left;
    height: 1rem;
    width:2.92rem;
    margin:1rem 0 0 0.8rem;
}

.foot_text{
    float: left;
    width: 80%;
    height: 0.68rem;
    font-size:0.24rem;
    font-family:MicrosoftYaHei;
    font-weight:400;
    color:rgba(51,51,51,1);
    margin:0.2rem 0 0 10%;
    border-bottom: 0.01rem solid rgba(187,187,187,1);
}
.foot_image{
    float: left;
    width: 0.35rem;
}
.down_guide{
    float: left;
    width:100%;
    height:0.55rem;
    font-size:0.48rem;
    font-family:MicrosoftYaHei;
    font-weight:400;
    color:rgba(51,51,51,1);
    text-align: center;
    margin-top: 0.8rem;
}
.guide_image {
    width:100%;
}
</style>